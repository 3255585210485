import React from 'react'
import EventsHeroImage from "assets/images/backgrounds/event.jpg";
import UpcomingEvents from 'pages/home/upcomingevents';
import image1 from "assets/images/siteimages/image1.jpg";
import image2 from "assets/images/siteimages/image2.jpg";
import image3 from "assets/images/siteimages/image3.jpg";

export default function Events() {
    const eventsData = [
        {
          title: "The PWCI Honored with Statewide Awards",
          image: image1,
          link: "/event1",
          description: "Event Description",
        },
        {
          title: "Another Upcoming Event Description Here",
          image: image2,
          link: "/event2",
          description: "Event Description",
        },
        {
          title: "Yet Another Event Coming Soon",
          image: image3,
          link: "/event3",
          description: "Event Description",
        },
      ];
  return (
    <div className="tw-mb-10">
      <div className="tw-relative tw-h-[50vh]">
        <img
          src={EventsHeroImage}
          alt="Background"
          className="tw-object-cover tw-w-full tw-h-full tw-opacity-80"
        />
        <div className="tw-absolute tw-inset-0 tw-bg-black tw-opacity-70" />{" "}
        {/* Overlay */}
        <div className="tw-absolute tw-inset-0 tw-flex md:tw-mx-32 tw-left-10 tw-items-center">
          <div className="md:tw-w-2/3">
            <h5 className="tw-text-white tw-text-3xl md:tw-text-6xl tw-font-bold">
              Events
            </h5>
          </div>
        </div>
      </div>
      <div className='tw-w-3/5 tw-m-auto tw-p-4'>
      <UpcomingEvents events={eventsData} />
      </div>
    </div>
  )
}
