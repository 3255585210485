import React from "react";
import DonateHeroImage from "assets/images/backgrounds/volunteers.jpg";
import Image1 from "assets/images/siteimages/Community.jpg";

export default function Volunteer() {
  return (
    <div className="tw-mb-10">
      <div className="tw-relative tw-h-[50vh]">
        <img
          src={DonateHeroImage}
          alt="Background"
          className="tw-object-cover tw-w-full tw-h-full tw-opacity-80"
        />
        <div className="tw-absolute tw-inset-0 tw-bg-black tw-opacity-70" />{" "}
        {/* Overlay */}
        <div className="tw-absolute tw-inset-0 tw-flex md:tw-mx-32 tw-left-10 tw-items-center">
          <div className="md:tw-w-2/3">
            <h5 className="tw-text-white tw-text-3xl md:tw-text-6xl tw-font-bold">
              Get Involved
            </h5>
          </div>
        </div>
      </div>

      <section id="donation-link" className="tw-bg-prim-gray tw-py-10">
        <div className="tw-mt-10 tw-w-3/4 tw-mx-auto tw-pb-10">
          <h3 className="tw-text-4xl tw-font-bold tw-text-center tw-mb-10">
            Volunteer at PWCI
          </h3>
          <div className="tw-m-auto tw-text-center tw-flex tw-flex-col tw-justify-center tw-items-center">
            <h5 className="tw-text-2xl tw-font-semibold tw-text-prim-purple">
              "Volunteers are the only human beings on the face of the earth who
              reflect this nation’s compassion, unselfish caring, patience, and
              just plain loving one another." – Erma Bombeck
            </h5>
            <div className="tw-relative tw-left-0 tw-bottom-0 tw-w-1/12 tw-h-1 tw-bg-prim-purple tw-my-5" />
          </div>
          <div className="tw-text-center tw-w-full">
            <p className="tw-text-lg tw-font-librefranklin tw-text-center md:tw-w-3/4 tw-m-auto">
              Your gifts of your time, talents, and financial support changes
              lives and helps people in our communities!
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="tw-mb-10 tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
          <div className="tw-relative tw-h-full tw-w-full md:tw-order-last tw-overflow-hidden">
            {/* Left column for image */}
            <img
              src={Image1} // Update with your image path
              alt="Objectives Illustration"
              className="tw-object-cover tw-h-full tw-w-full tw-z-0"
            />
            {/* Right-side SVG, positioned absolutely */}
            <div className="tw-hidden md:tw-flex tw-left-[-100px] tw-absolute tw-top-1/2 tw-z-20 tw-transform  tw--translate-y-1/2">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="274.322"
                height="600"
                viewBox="0 0 274.322 600"
              >
                <path
                  id="Subtraction_16"
                  data-name="Subtraction 16"
                  d="M107.772,600H8.206A8.211,8.211,0,0,1,0,591.8V8.2A8.21,8.21,0,0,1,8.206,0L163.213,0l80.469,139.23a222.608,222.608,0,0,1,1.724,222.444L107.773,600Z"
                  fill="#9f6198"
                />
              </svg>
            </div>
          </div>
          <div className="tw-bg-prim-pc-purple tw-flex tw-justify-center tw-relative tw-overflow-hidden tw-z-10">
            <div className="tw-p-4 md:tw-p-10 md:tw-m-auto md:tw-w-9/12 tw-flex tw-flex-col">
              <div className="tw-relative tw-inline-block tw-mb-4 tw-text-white tw-w-max">
                <h4 className="tw-text-xl md:tw-text-2xl tw-font-bold tw-mb-4">
                  WHY VOLUNTEER?
                </h4>
                <div className="tw-absolute tw-left-0 tw-bottom-0 tw-w-1/3 tw-h-1 tw-bg-prim-orange tw-mt-2" />
              </div>
              <p className="tw-text-white tw-text-lg tw-mb-2">
                In addition to feeling great about your efforts, volunteers have
                the opportunity to:
              </p>
              <ul className="tw-list-inside tw-text-white tw-list-none tw-text-lg">
                <li>1. Experience personal growth</li>
                <li>2. Give back to the community through service to others</li>
                <li>3. Explore and/or discover a hidden passion</li>
                <li>4. Expand knowledge and skills through training</li>
                <li>
                  5. Fulfill community service requirements for school credit
                </li>
                <li>6. Develop new relationships and partnerships</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
