import React from "react";

const UpcomingEvents = ({ events }) => {
  return (
    <div className="">
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-8">
        {events.map((event, index) => (
          <div key={index} className="tw-overflow-hidden tw-cursor-pointer tw-rounded-md">
            <a href={event.link} className="tw-block tw-h-full">
              <div className="tw-flex tw-flex-col tw-h-full">
                <div className=" tw-relative">
                  <div className="tw-leading-tight tw-z-20 tw-absolute tw-left-0 tw-top-0 tw-m-auto tw-text-center tw-px-3 tw-py-2 tw-text-white tw-bg-prim-dark tw-duration-75 tw-ease-in-out">
                    <p className="tw-font-bold tw-text-xl tw-leading-tight">07</p>
                    <p className="tw-text-xs tw-leading-tight">Nov 2024</p>
                  </div>
                  <div className="tw-z-0 tw-transition-transform tw-duration-300 hover:tw-scale-105">
                    <img
                      src={event.image}
                      alt={event.title}
                      className="tw-w-full tw-h-[200px] tw-object-cover"
                    />
                  </div>
                </div>
                <div className="tw-flex tw-flex-col tw-relative tw-bg-gray-100 tw-flex-grow tw-p-4 tw-min-h-48">
                  <p className="tw-text-prim-dark tw-mb-3 tw-text-lg tw-font-bold tw-leading-tight">
                    {event.title}
                  </p>

                  {/* <p className="tw-text-gray-600 tw-text-sm">
                    {event.description}
                  </p> */}
                  <div className="tw-absolute tw-right-0 tw-bottom-0  tw-py-1 tw-text-white hover:tw-bg-prim-purple tw-bg-prim-orange tw-duration-75 tw-ease-in-out">
                    <a href={event.link} className="tw-px-3 tw-text-xs">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpcomingEvents;
