import React from "react";
import DonateHeroImage from "assets/images/backgrounds/donate.jpg";
import volunteericon from "assets/images/icons/volunteer.png";
import connecticon from "assets/images/icons/connect.png";
import donateicon from "assets/images/icons/donate.png";
import {
  LinkdeinPageLink,
  XTwitterPageLink,
  FacebookPageLink,
  VolunteerPageLink,
} from "lib/constants";
import facebookIcon from "assets/images/icons/facebook.png";
import twitterIcon from "assets/images/icons/twitter.png";
import linkedinIcon from "assets/images/icons/linkedin.png";

import { Accordion, Container, Row, Col } from "react-bootstrap";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Donate() {
  const faqList = [
    {
      question: "How are donations recognized?",
      answer:
        "All donations are acknowledged in our Annual Report. Contributions made through specific fundraising campaigns may also be recognized at campaign events, in relevant publications, and occasionally in local newspapers. Donations can be made in honor or memory of loved ones, friends, or colleagues. Requests to remain anonymous are fully respected.",
    },
    {
      question:
        "Are donations to Providence Whole Care International tax-deductible?",
      answer:
        "Providence Whole Care International operates under relevant Kenyan tax laws.",
    },
    {
      question: "Will I receive confirmation of my donation?",
      answer:
        "Yes, each donation is acknowledged in a letter shortly after it is received.",
    },
    {
      question: "For questions about any of these methods of giving",
      answer:
        "Please contact our team at +254 712 345 678 or email info@providencewholecare.org.",
    },
  ];
  return (
    <div className="tw-mb-10">
      <div className="tw-relative tw-h-[50vh]">
        <img
          src={DonateHeroImage}
          alt="Background"
          className="tw-object-cover tw-w-full tw-h-full tw-opacity-80"
        />
        <div className="tw-absolute tw-inset-0 tw-bg-black tw-opacity-70" />{" "}
        {/* Overlay */}
        <div className="tw-absolute tw-inset-0 tw-flex md:tw-mx-32 tw-left-10 tw-items-center">
          <div className="md:tw-w-2/3">
            <h5 className="tw-text-white tw-text-3xl md:tw-text-6xl tw-font-bold">
              Make a Donation
            </h5>
          </div>
        </div>
      </div>

      <section className="tw-flex-col tw-mt-5 tw-py-10 md:tw-mb-16 tw-w-3/4 tw-mx-auto">
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4 tw-mt-4">
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mb-5 tw-text-center tw-px-4">
            <img
              className="tw-h-24 tw-w-24"
              src={volunteericon}
              alt="life coach icon"
            />
            <h5 className="tw-text-center tw-mt-6 tw-text-2xl tw-font-semibold tw-text-prim-purple">
              Volunteer
            </h5>
            <p className="tw-mt-2 tw-text-lg">
              Opportunities to give back to your community are available.
            </p>
            <a
              className="tw-mt-6 tw-px-4 tw-py-2 tw-bg-prim-purple tw-text-white tw-rounded-sm"
              href={VolunteerPageLink}
            >
              More Information
            </a>
          </div>

          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mb-5 tw-text-center tw-px-4">
            <img
              className="tw-h-24 tw-w-24"
              src={donateicon}
              alt="counseling icon"
            />
            <h5 className="tw-text-center tw-mt-6 tw-text-2xl tw-font-semibold tw-text-prim-purple">
              Donate
            </h5>
            <p className="tw-mt-2 tw-text-lg">
              Help us empower people to achieve self-sufficiency.
            </p>
            <a
              className="tw-mt-6 tw-px-4 tw-py-2 tw-bg-prim-purple tw-text-white tw-rounded-sm"
              href="#donation-link"
            >
              Get Details
            </a>
          </div>

          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mb-5 tw-text-center tw-px-4">
            <img
              className="tw-h-24 tw-w-24"
              src={connecticon}
              alt="conference speaker icon"
            />
            <h5 className="tw-text-center tw-mt-6 tw-text-2xl tw-font-semibold tw-text-prim-purple">
              Connect With Us
            </h5>
            <p className="tw-mt-2 tw-text-lg">
              See what we are up to and connect with us on our social media
              platforms.
            </p>
            <div>
              <div className="tw-flex tw-justify-center tw-items-center tw-gap-4 tw-mt-4">
                {/* Social Icons */}
                <a
                  href={FacebookPageLink}
                  className="tw-text-prim-dark tw-text-2xl tw-hover:text-blue-100"
                >
                  <FaFacebook style={{}} size="25px" />
                </a>
                <a
                  href={XTwitterPageLink}
                  className="tw-text-prim-darktw-text-2xl tw-hover:text-blue-100"
                >
                  <FaXTwitter style={{}} size="25px" />
                </a>
                <a
                  href={LinkdeinPageLink}
                  className="tw-text-prim-dark tw-text-2xl tw-hover:text-blue-100"
                >
                  <FaLinkedin style={{}} size="25px" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="donation-link" className="tw-bg-prim-gray tw-py-10">
        <div className="tw-mt-10 tw-w-3/4 tw-mx-auto tw-pb-10">
          <div className="tw-m-auto tw-text-center tw-flex tw-flex-col tw-justify-center tw-items-center">
            <h5 className="tw-text-3xl tw-font-bold tw-text-prim-purple">
              Thank you for supporting us!
            </h5>
            <div className="tw-relative tw-left-0 tw-bottom-0 tw-w-1/12 tw-h-1 tw-bg-prim-purple tw-my-5" />
          </div>
          <div className="tw-text-center tw-w-full">
            <p className="tw-text-lg tw-font-archivo tw-text-center md:tw-w-3/4 tw-m-auto">
              Providence whole Care international is registered under Kenyan
              law. Donations are eligible for tax deductions as per the Income
              Tax Act (Cap 470) of Kenya. All contributions are acknowledged in
              writing, and donors are recognized in our annual Impact Report.
              Requests to remain anonymous are fully respected.
            </p>
          </div>
        </div>
      </section>

      <section className="tw-py-10">
        <div className="tw-text-center tw-mx-4">
          <p className="tw-text-2xl tw-font-semibold">
            Frequently Asked Questions{" "}
          </p>
          <Container className="mt-4">
            <Row>
              {/* Split into two columns */}
              <Col md={6}>
                <Accordion>
                  {faqList
                    .slice(0, Math.ceil(faqList.length / 2))
                    .map((faq, index) => (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>{faq.question}</Accordion.Header>
                        <Accordion.Body>{faq.answer}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </Col>
              <Col md={6}>
                <Accordion>
                  {faqList
                    .slice(Math.ceil(faqList.length / 2))
                    .map((faq, index) => (
                      <Accordion.Item
                        eventKey={index + Math.ceil(faqList.length / 2)}
                        key={index + Math.ceil(faqList.length / 2)}
                      >
                        <Accordion.Header>{faq.question}</Accordion.Header>
                        <Accordion.Body>{faq.answer}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </div>
  );
}
