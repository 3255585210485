export const HomePageLink = "/";
export const AboutUsPageLink = "/about";
export const OrganizationStructurePageLink = "/organization";
export const FinancialCapacityStatetmentPageLink = "/financialcapacitystatement";
export const AllEventsPageLink = "/events";
export const AchievementsPageLink = "/achievements";
export const PartnersPageLink = "/partnerships";
export const ProgrammesPageLink = "/ourprograms";
export const FacebookPageLink = "/";
export const XTwitterPageLink = "/";
export const LinkdeinPageLink = "/";
export const DonatePageLink = "/donate"
export const VolunteerPageLink = "/volunteer"

