import React, { useRef } from "react";
import { motion, useInView } from "framer-motion"; // Import Framer Motion
// import image1 from "assets/images/siteimages/image1.jpg";
import EnvIcon from "assets/images/icons/environmentchange.png";
import ResearchIcon from "assets/images/icons/research-and-development.png";
import AdvocateIcon from "assets/images/icons/protest.png";
import AgroTechIcon from "assets/images/icons/chip.png";
import HealthIcon from "assets/images/icons/healthcare.png";
import SocialProtectionIcon from "assets/images/icons/consumer-protection.png";
import CardContent from "./cardcontent";

import agrotechnology from "assets/images/siteimages/agrotechnology.jpg";
import socialprotectionprogram from "assets/images/siteimages/socialprotectionprogram.jpg";
import climatechange from "assets/images/siteimages/climatechange.jpg";
import healthprograms from "assets/images/siteimages/healthprograms.jpg";
import lobbyingandadvocacy from "assets/images/siteimages/lobbyingandadvocacy.jpg";
// import psychosocialsupport from "assets/images/siteimages/psychosocialsupport.jpg";
import researchandtraining from "assets/images/siteimages/researchandtraining.jpg";
import { ProgrammesPageLink } from "lib/constants";

export default function ProgrammesCards() {

  const ref = useRef(null);
  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

  const cardData = [
    {
      title: "Social Protection Programs",
      description: "Providence Whole Care International's Social Protection Programs support vulnerable groups like OVCs and PLWHAs with psycho-social support for well-being.",
      imageUrl: socialprotectionprogram,
      iconUrl: SocialProtectionIcon,
      linkUrl: ProgrammesPageLink,
      bgcolor: "tw-bg-prim-pc-purple1",
      bgcolorlight: "tw-bg-prim-pc-purple1",
    },
    {
      title: "Environment/ Climate change and adaptation",
      description: "Our organization collaborates with grassroots communities to implement innovative strategies addressing climate change and promoting sustainable development.",
      imageUrl: climatechange,
      iconUrl: EnvIcon,
      linkUrl: ProgrammesPageLink,
      bgcolor: "tw-bg-prim-pc-brown",
      bgcolorlight: "tw-bg-prim-pc-brown",
    },
    {
      title: "Research and Training",
      description: "Providence Whole Care International empowers individuals through tailored programs focusing on life skills, entrepreneurship, financial literacy, and employability.",
      imageUrl: researchandtraining,
      iconUrl: ResearchIcon,
      linkUrl: ProgrammesPageLink,
      bgcolor: "tw-bg-prim-pc-blue",
      bgcolorlight: "tw-bg-prim-pc-blue",
    },
    {
      title: "Lobbying and Advocacy",
      description: "We promote public participation through lobbying and advocacy, ensuring communities' voices are heard in addressing environmental and humanitarian issues.",
      imageUrl: lobbyingandadvocacy,
      iconUrl: AdvocateIcon,
      linkUrl: ProgrammesPageLink,
      bgcolor: "tw-bg-prim-pc-pink",
      bgcolorlight: "tw-bg-prim-pc-pink",
    },
    {
      title: "Innovative Agro-Energy Technological Initiatives",
      description: "We address urban and rural challenges through innovative agro-energy solutions.",
      imageUrl: agrotechnology,
      iconUrl: AgroTechIcon,
      linkUrl: ProgrammesPageLink,
      bgcolor: "tw-bg-prim-pc-purple",
      bgcolorlight: "tw-bg-prim-pc-purple",
    },
    {
      title: "Health Programs",
      description: "Providence Whole Care International's Health Programs improve community well-being through advocacy and sensitization on HIV, malaria, and emerging health issues.",
      imageUrl: healthprograms,
      iconUrl: HealthIcon,
      linkUrl: ProgrammesPageLink,
      bgcolor: "tw-bg-prim-pc-pink",
      bgcolorlight: "tw-bg-prim-pc-pink",
    },
  ];

  return (
    <div className="md:tw-grid tw-grid-cols-3 tw-gap-10 tw-m-auto tw-mb-10 tw-px-10 md:tw-px-0">
      {cardData.map((card, index) => (
        <motion.div
          key={index}
          className="program-card tw-mb-10 md:tw-mb-0 !tw-rounded-md"
          initial={{ opacity: 0, y: 50 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          //animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: index * 0.2 }} // Stagger animation for each card
          whileInView={{ opacity: 1, y: 0 }} // Trigger animation when in view
          viewport={{ once: true, amount: 0.2 }} // Trigger animation when 20% of the card is in the viewport
        >
          <CardContent card={card} />
        </motion.div>
      ))}
    </div>
  );
}
