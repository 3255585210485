import React, { useState } from "react";
import LogoImage from "assets/images/PWCI_Logo.png";
import "./navbarstyle.css";
import image1 from "assets/images/siteimages/image1.jpg";
import image2 from "assets/images/siteimages/image2.jpg";
import image3 from "assets/images/siteimages/image4.jpg";
import {
  AboutUsPageLink,
  AchievementsPageLink,
  DonatePageLink,
  FinancialCapacityStatetmentPageLink,
  HomePageLink,
  OrganizationStructurePageLink,
  PartnersPageLink,
  ProgrammesPageLink,
} from "lib/constants";
import { NavLink } from "react-router-dom";
import { LuMenuSquare } from "react-icons/lu";
import { CgCloseR } from "react-icons/cg";

export default function Navbar() {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const [isWhoWeAreOpen, setIsWhoWeAreOpen] = useState(false);

  const toggleWhoWeAreMenu = () => {
    console.log("wqsdafdsa");

    setIsWhoWeAreOpen(!isWhoWeAreOpen);
  };

  return (
    <nav className="tw-flex tw-flex-col tw-h-[15vh] tw-relative tw-shadow-lg">
      <div className=" tw-bg-prim-purple tw-flex tw-justify-end tw-items-center tw-text-primary-white tw-font-semibold tw-text-sm">
        <div className="tw-mx-2 tw-py-2">
          <a href="/">Courses</a>
          <span className="tw-mx-2">|</span>
          <a href="/">FAQ's</a>
        </div>
        <div className="tw-mx-4 tw-py-2 tw-hidden md:tw-flex">
          <a href="/">PWCI Home Page</a>
        </div>
        <div className="tw-h-full tw-bg-prim-orange2 tw-py-2">
          <a href={DonatePageLink} className=" tw-px-6 tw-h-full">
            Donate
          </a>
        </div>
      </div>
      <div className="tw-relative tw-h-full tw-px-4 md:tw-px-6 tw-flex tw-flex-row tw-justify-between tw-items-center tw-p-2 tw-bg-white tw-shadow-sm tw-z-20">
        <div className="tw-flex tw-flex-row tw-items-center">
          <NavLink
            to={HomePageLink}
            className="tw-flex tw-flex-row tw-items-center"
          >
            <div>
              <img
                src={LogoImage}
                alt="PWCI Logo"
                className="tw-h-10 md:tw-h-12"
              />
            </div>
            <div className="tw-ml-2">
              <p className="tw-text-md md:tw-text-lg tw-font-semibold tw-uppercase">
                Providence Whole Care International
              </p>
              <p className="tw-text-xs tw-font-semibold tw-text-gray-800">
                Making an Impact, Creating Change
              </p>
            </div>
          </NavLink>
        </div>

        <div className="menu-icon" onClick={handleShowNavbar}>
          <LuMenuSquare size={30} />
        </div>
        <div className={`nav-elements   ${showNavbar && "active"}`}>
          <div className="tw-flex tw-flex-row tw-justify-end tw-w-full">
            <div onClick={handleShowNavbar} className="tw-mt-10">
              <CgCloseR size={30} />
            </div>
          </div>
          <div className=" ">
            <ul className="ul-flex ul-flex-col ul-space-y-4 ul-p-4">
              <li>
                <NavLink
                  to="/"
                  className="ul-text-gray-800 ul-hover:ul-text-blue-600"
                  onClick={handleShowNavbar}
                >
                  Home
                </NavLink>
              </li>
              <li className="ul-relative">
                <button
                  className="ul-text-gray-800 ul-hover:ul-text-blue-600 ul-cursor-pointer ul-w-full text-left"
                  onClick={toggleWhoWeAreMenu}
                >
                  Who We Are
                </button>
                <div
                  id="whoWeAreMenu"
                  className="ul-hidden ul-absolute ul-left-0 ul-bg-white ul-shadow-lg ul-z-10 ul-rounded-md ul-top-full ul-w-full ul-space-y-2"
                >
                  <NavLink
                    to={OrganizationStructurePageLink}
                    className="ul-block ul-py-2 ul-px-4 ul-hover:ul-bg-blue-100"
                    onClick={handleShowNavbar}
                  >
                    Org Structure
                  </NavLink>
                  <NavLink
                    to={FinancialCapacityStatetmentPageLink}
                    className="ul-block ul-py-2 ul-px-4 ul-hover:ul-bg-blue-100"
                    onClick={handleShowNavbar}
                  >
                    Financial Statement
                  </NavLink>
                </div>
              </li>
              <li>
                <NavLink
                  to={AchievementsPageLink}
                  className="ul-text-gray-800 ul-hover:ul-text-blue-600"
                  onClick={handleShowNavbar}
                >
                  Achievements
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={PartnersPageLink}
                  className="ul-text-gray-800 ul-hover:ul-text-blue-600"
                  onClick={handleShowNavbar}
                >
                  Partnerships
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        {/* The other part starts here */}
        <div className=" tw-flex-row md:tw-grid md:tw-grid-flow-row md:tw-grid-cols-4 tw-gap-2 tw-h-full tw-hidden">
          <div className="nav-drodn whoweare tw-flex tw-items-center tw-justify-center tw-h-full">
            <a
              href="#"
              className="tw-py-2 tw-text-md tw-font-semibold tw-h-full tw-flex tw-items-center tw-justify-center"
            >
              Who we Are
            </a>
            {/* This is a drop down div that shows more menus */}
            <div className="dropdown-items whoweare-dropdown tw-bg-white tw-z-10 tw-absolute tw-w-full tw-left-0 tw-top-[6vh] tw-hidden tw-p-5">
              <div className="md:tw-grid tw-grid-cols-3 tw-py-4">
                <div className="tw-flex tw-flex-col tw-w-max  tw-items-center tw-mx-auto">
                  <div className="tw-flex tw-flex-col">
                    <a
                      className="tw-mb-2 tw-text-lg tw-font-semibold"
                      href={AboutUsPageLink}
                    >
                      Mission and Values
                    </a>
                    <a
                      className="tw-mb-2 tw-text-lg tw-font-semibold"
                      href={OrganizationStructurePageLink}
                    >
                      Organization Structure
                    </a>
                    <a
                      className="tw-mb-2 tw-text-lg tw-font-semibold"
                      href={FinancialCapacityStatetmentPageLink}
                    >
                      {" "}
                      Financial Accountability and Compliance
                    </a>
                  </div>
                </div>
                {/* image with grid */}
                <div className="md:tw-grid tw-grid-cols-3 tw-gap-10 tw-col-span-2">
                  <a href={AboutUsPageLink}>
                    <div>
                      <div className="tw-w-full tw-h-[30vh] tw-object-cover tw-rounded-md">
                        <img
                          src={image1}
                          alt="mission"
                          className="tw-rounded-md tw-h-full tw-object-cover tw-w-full"
                        />
                      </div>
                      <div className="tw-mt-4">
                        <p className="tw-text-lg tw-font-semibold tw-text-bg-primary">
                          Our Mission
                        </p>
                      </div>
                      <div className="tw-mt-2">
                        <p className="tw-text-md tw-font-semibold tw-text-gray-600">
                          Provision of comprehensive professional services aimed
                          at promoting a clean and healthy environment to
                          enhance socio-economic development.
                        </p>
                      </div>
                    </div>
                  </a>
                  <a href={OrganizationStructurePageLink}>
                    <div>
                      <div className="tw-w-full tw-h-[30vh] tw-object-cover tw-rounded-md">
                        <img
                          src={image2}
                          alt="mission"
                          className="tw-rounded-md tw-h-full tw-object-cover tw-w-full"
                        />
                      </div>
                      <div className="tw-mt-4">
                        <p className="tw-text-lg tw-font-semibold tw-text-bg-primary">
                          Organization Structure
                        </p>
                      </div>
                      <div className="tw-mt-2">
                        <p className="tw-text-md tw-font-semibold tw-text-gray-600">
                          To achieve its mission and objectives, Providence
                          Whole Care International has a structured hierarchy
                          comprising executive management, skilled technical
                          staff, and dedicated volunteers committed to its
                          mission.
                        </p>
                      </div>
                    </div>
                  </a>
                  <a href={FinancialCapacityStatetmentPageLink}>
                    <div>
                      <div className="tw-w-full tw-h-[30vh] tw-object-cover tw-rounded-md">
                        <img
                          src={image3}
                          alt="mission"
                          className="tw-rounded-md tw-h-full tw-object-cover tw-w-full"
                        />
                      </div>
                      <div className="tw-mt-4">
                        <p className="tw-text-lg tw-font-semibold tw-text-bg-primary">
                          Financial Accountability and Compliance
                        </p>
                      </div>
                      <div className="tw-mt-2">
                        <p className="tw-text-md tw-font-semibold tw-text-gray-600">
                          Providence Whole Care International ensures financial
                          integrity through robust budgeting, reporting, and
                          compliance systems that safeguard resources.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-drodn programs tw-flex tw-items-center tw-justify-center tw-h-full">
            <a
              href={"#"}
              className="tw-py-2 tw-text-md tw-font-semibold tw-h-full tw-flex tw-items-center tw-justify-center"
            >
              Programs
            </a>
            {/* This is a drop down div that shows more menus */}
            <div className="dropdown-items programs-dropdown tw-bg-white tw-z-10 tw-absolute tw-w-full tw-left-0 tw-top-[6vh] tw-hidden tw-p-5">
              <div className="tw-w-11/12 tw-mx-auto">
                <p className="tw-text-lg tw-mb-3 tw-text-prim-purple">
                  Our programmes
                </p>
                <div className="tw-grid tw-grid-cols-3 tw-gap-0.5">
                  <div className="tw-border-r tw-border-gray-300 tw-h-32 tw-flex tw-flex-col tw-ml-5 tw-p-4">
                    <div className="tw-text-lg tw-font-semibold tw-mb-2">
                      <a href={ProgrammesPageLink}> Environment Programs</a>
                    </div>
                    <div className="tw-text-lg tw-font-semibold tw-mb-2">
                      <a href={ProgrammesPageLink}> Research and Training</a>
                    </div>
                    <div className="tw-text-lg tw-font-semibold tw-mb-2">
                      <a href={ProgrammesPageLink}> Lobbying and Advocacy</a>
                    </div>
                  </div>
                  <div className="tw-border-r tw-border-gray-300 tw-h-32 tw-flex tw-flex-col tw-ml-5 tw-p-4">
                    <div className="tw-text-lg tw-font-semibold tw-mb-2">
                      <a href={ProgrammesPageLink}>
                        Innovative Agro-Energy Technological Initiatives
                      </a>
                    </div>
                    <div className="tw-text-lg tw-font-semibold tw-mb-2">
                      <a href={ProgrammesPageLink}>Health Programs</a>
                    </div>
                    <div className="tw-text-lg tw-font-semibold tw-mb-2">
                      <a href={ProgrammesPageLink}>
                        Social Protection Programs
                      </a>
                    </div>
                  </div>
                  <div className="tw-h-32 tw-flex  tw-ml-5 tw-flex-col tw-p-4">
                    <div className="tw-text-lg tw-font-semibold tw-mb-2">
                      <a href={ProgrammesPageLink}>Economic Empowerment</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-drodn achievement tw-flex tw-items-center tw-justify-center tw-h-full">
            <a
              href={AchievementsPageLink}
              className="tw-py-2 tw-text-md tw-font-semibold tw-h-full tw-flex tw-items-center tw-justify-center"
            >
              Achievements
            </a>
            {/* This is a drop down div that shows more menus */}
            {/* <div className="dropdown-items achievement-dropdown tw-bg-white tw-z-10 tw-absolute tw-w-full tw-left-0 tw-top-[6vh] tw-hidden tw-p-5">
              <p>Achievements goes here</p>
            </div> */}
          </div>
          <div className="nav-drodn partnerships tw-flex tw-items-center tw-justify-center tw-h-full">
            <a
              href={PartnersPageLink}
              className="tw-py-2 tw-text-md tw-font-semibold tw-h-full tw-flex tw-items-center tw-justify-center"
            >
              Partnerships
            </a>
            {/* This is a drop down div that shows more menus */}
            {/* <div className="dropdown-items partnerships-dropdown tw-bg-white tw-z-10 tw-absolute tw-w-full tw-left-0 tw-top-[6vh] tw-hidden tw-p-5">
              <p>Partnerships here</p>
            </div> */}
          </div>
        </div>
      </div>
    </nav>
  );
}
